<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<div class="filter-item">
					<label class="label">姓名:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.realName"
						placeholder="请输入姓名"
					></el-input>
				</div>
				<div class="filter-item">
					<label class="label">手机号:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.userName"
						placeholder="请输入手机号"
					></el-input>
				</div>
				<div class="filter-item">
					<label class="label">操作模块:</label>
					<el-select class="option-item" v-model="searchData.moduleName" placeholder="请选择">
						<el-option label="全部" :value="null"></el-option>
						<el-option
							v-for="item in typeOptions"
							:key="item.value"
							:label="item.label"
							:value="item.label"
						></el-option>
					</el-select>
				</div>
                <div class="filter-item">
					<label class="label">操作内容:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.operationDesc"
						placeholder="请输入操作内容"
					></el-input>
				</div>
			</div>
			<div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">操作时间:</label>
					<el-date-picker
						v-model="searchTime"
						type="datetimerange"
						range-separator="～"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</div>
			</div>
			<div>
				<div class="filter-item">
					<el-button type="primary" style="width:100px;" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="realName" label="操作人" width="120"></el-table-column>
				<el-table-column prop="moduleName" label="操作模块" width="100"></el-table-column>
				<el-table-column prop="createTime" label="操作时间" width="100"></el-table-column>
				<el-table-column prop="operationDesc" label="操作内容"></el-table-column>
			</el-table>

			<el-pagination
				v-if="searchData.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="searchData.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchData.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import {
	getOperationLog
} from '@/api/setting'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			searchTime: '',  //时间
			searchData: {
				userName: '',
				realName: '',
				moduleName: '',
				endDate: '',     //结束时间
                startDate: '',   //开始时间
                operationDesc:'',   //操作内容
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			loading: false,
			tableData: [],
			typeOptions: [{
				value: 1,
				label: '商品'
			}, {
				value: 2,
				label: '订单'
			}, {
				value: 3,
				label: '会员'
			}, {
				value: 4,
				label: '推手'
			}, {
				value: 5,
				label: '客服'
			}, {
				value: 6,
				label: '直播'
			}, {
				value: 7,
				label: '设置'
			}, {
				value: 8,
				label: '售后'
			},],
		}
	},
	async created () {
		this.getList();

	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			try {
				let data = this.searchData;
				data.startDate = '';
				data.endDate = '';
				if (this.searchTime) {
					data.startDate = this.searchTime[0];
					data.endDate = this.searchTime[1];
				}

				let result = await getOperationLog(data);
				this.tableData = result.data.records;
				this.searchData.total = result.data.total;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		search () {
			this.searchData.pageNo = 1
			if (!this.searchData.total) {
				this.searchData.pageSize = 20
			}
			this.getList()
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.searchData.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.searchData.pageNo = val;
			this.getList();
		},
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.option-item {
	/deep/.el-input {
		width: 150px !important;
	}
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
</style>
